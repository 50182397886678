import { lazy, Suspense, useContext, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { GroupsProvider } from "./routes/realms/context";
import { TemplatesProvider } from "./routes/templates/context";
import ErrorBoundaryWithNavigate from "./error-boundary";
import { NavContext } from "./context";
import { UserContext } from "./routes/auth/context";
import { SideSection } from "./components/section";
import { MyImageArticle } from "./components/articles";
import { Menu } from "./components/menu";
import { CloseBtnStd, pressBtnClass } from "./components/buttons";
import dropIcon from "../assets/img/drop.png";
import Popup from "./components/popup";
import { LoadingGif } from "./components/loading-gif";
import MainMain from "./routes/main";

// Lazy load components
const GetStarted = lazy(() => import("./routes/getstarted"));
const Auth = lazy(() => import("./routes/auth"));
const Groups = lazy(() => import("./routes/realms/index"));
const Profile = lazy(() => import("./routes/profile"));
const Templates = lazy(() => import("./routes/templates/index"));
const ErrorPage = lazy(() => import("./routes/error"));

export const Land = () => {  

  const { 
    bg,
    leftSideContent,
    rightSideContent,
    closeFunc,
    pageTitle,
    menu,
    tabs,
    popupTitle,
    popupContent,
    popupType,
    popupFunc,
    popupMenu,
    popupCloseable,
    showPopup,
    setShowPopup,
    setPageTitle,
    setLeftSidebar,
    setRightSidebar,
    setTabs
   } = useContext(NavContext);
   const { currentUser } = useContext(UserContext);

   const navigate = useNavigate();
   const location = useLocation();

   const nobgHeader = location.pathname === "/"

   useEffect(() => {
    if (location.pathname === "/") {
      setPageTitle("Documents and Dragons");
    }
    if (location.pathname === "/getstarted") {
      setPageTitle("Get Started");
    }
    if (location.pathname === "/profile") {
      setPageTitle("Profile");
    }
    if (location.pathname === "/auth") {
      setPageTitle("Login");
    }
    if (location.pathname === "/realms") {
      setPageTitle("Realms");
    }
    if (location.pathname === "/templates") {
      setPageTitle("Templates");
    }
    if (location.pathname === "/error") {
      setPageTitle("Something went wrong");
    }
  }, [location.pathname, setPageTitle]);

  useEffect(() => {    
    if (location.pathname === "/" || location.pathname === "/getstarted" || location.pathname === "/error" || location.pathname === "/profile") { 
      setTabs([
        {
            title: "Home",
            func: () => navigate("/"),
            active: true
        }
      ]);
      setLeftSidebar(null);
      setRightSidebar(null);
    }
  }, [setTabs, navigate, location.pathname, setLeftSidebar, setRightSidebar]);

  return (
    <>
    <BackgroundContainer bg={bg}>
    {!nobgHeader && <Header pageTitle={pageTitle} nobgHeader={nobgHeader}/>}
    <SideSection>{leftSideContent}</SideSection>
    <ContentContainer nobgHeader={nobgHeader} closeFunc={closeFunc} tabs={tabs}>
          <ErrorBoundaryWithNavigate>
            <TemplatesProvider>
              <GroupsProvider>
                      <Suspense fallback={<LoadingGif/>}>
                        <Routes>
                          <Route index element={<MainMain />} />
                          <Route path="getstarted" element={<GetStarted />} />              
                          <Route path="error" element={<ErrorPage/>}/>
                          <Route path="profile" element={<Profile/>}/>
                          <Route path="auth/*" element={<Auth />}/>
                          <Route path="realms/*" element={<Groups />}/>
                          <Route path="templates/*" element={<Templates/>}/>      
                          <Route path="*" element={<Navigate replace to="/" />}/>
                        </Routes>
                      </Suspense>
              </GroupsProvider>
            </TemplatesProvider>
          </ErrorBoundaryWithNavigate>
        </ContentContainer>
        <SideSection>{rightSideContent}</SideSection>
        <UserProfile currentUser={currentUser} navigate={navigate}/>
        <Menu buttons={menu}/>
      </BackgroundContainer>
      <Popup popupMenu={popupMenu} popupTitle={popupTitle} popupContent={popupContent} popupType={popupType} popupFunc={popupFunc} showPopup={showPopup} setShowPopup={setShowPopup} closeable={popupCloseable}/>
      </>
  )
}


const InnerMainSection = ({ children, closeFunc, tabs }) => {
  return (
    <>
      <menu className="relative w-full px-4 items-center flex justify-center bg-darker border-b-future overflow-x-auto overflow-y-hidden">
      {tabs.map((tab, index) => (
        <button key={index} className={`${pressBtnClass} text-light text-base pb-1 px-3 pt-1 mx-1 rounded-t-md whitespace-nowrap ${tab.active ? 'font-bold border-b-future' : null}`} onClick={tab.func}>
          {tab.image && <><img src={tab.image} className="h-6 inline-block mr-1 drop-shadow-lg shadow-white" alt="icon" /> </>}
          {tab.title}
        </button>
        ))}
      </menu>
      {children}      
      {closeFunc && <CloseBtnStd extraClass="absolute top-0 right-0" func={closeFunc}/>}
    </>
  );
}

const BackgroundContainer = ({ bg, children }) => (
  <main
    style={{ backgroundImage: `url(${bg})` }}
    className="md:px-28 px-4 bg-blend-darken w-[100svw] h-[100svh] fixed bg-cover grid grid-cols-6 md:grid-cols-8 gap-2 font-serif"
  >
    <div className="fixed bg-black/30 w-full h-screen backdrop-blur-sm"></div>
    {children}
  </main>
);

const Header = ({ nobgHeader, pageTitle }) => {
  if (nobgHeader) return null;
  return (
    <header className="flex flex-col fixed top-0 h-[10svh] w-full border-b-future justify-top">
      <div className="fixed bg-black/50 w-full h-[10svh] backdrop-blur"></div>
      <h1 className="relative font-superserif text-light text-center font-bold md:text-2xl text-xl mt-1 mx-20 whitespace-nowrap overflow-hidden">
        {pageTitle}
      </h1>
    </header>
  );
};

const ContentContainer = ({ nobgHeader, closeFunc, tabs, children }) => {
  if (nobgHeader) return children;
  return (
    <section className="relative top-[5svh] mx-auto h-[90svh] flex-col col-span-6 flex items-center justify-between border-stone shadow-2xl shadow-black bg-primary text-light bg-opacity-95 pb-6 chain-in-fast">
      <InnerMainSection closeFunc={closeFunc} tabs={tabs}>
        {children}
      </InnerMainSection>
    </section>
  );
};

const UserProfile = ({ currentUser, navigate }) => {
  if (!currentUser) return null;
  return (
    <article className="fixed top-1 left-1 items-center">
      <MyImageArticle picture={currentUser.profilePic} extraClass="w-16 h-16 rounded-full border-red-600 border" onClick={() => navigate("/profile")} />
      <p className="text-light text-sm flex gap-1">
        <img src={dropIcon} alt="credits" className="h-4" />
        {currentUser.credits}
      </p>
    </article>
  );
};