import { forwardRef } from 'react';
import { InvisArticle } from './articles';
import { CheckBox, DropDown, DropDownExtended, Input2, TextArea } from './inputs';

export const Field = forwardRef(({ formMode, handleSubmit, item, children, extraClass }, ref) => {

    const moreClass = extraClass ? extraClass : "";

    return (
        <>
        { formMode ?
        <form onSubmit={handleSubmit} className={`${moreClass} flex justify-center w-full relative`}>
            { (item.type === "textarea") &&
            <TextArea ref={ref} extraClass="w-full" value={item.value} onChange={(e) => item.setValue(e.target.value)} required={item.required} maxLength={item.max_length} nolabel={true}/>
            }
            { (item.type === "text" || item.type === "number") &&
            <Input2 ref={ref} extraClass="w-full" field={item} onChange={(e) => item.setValue(e.target.value)} required={item.required} label="none"/>
            }
            { (item.type === "select") && 
            <DropDown ref={ref} extraClass="w-full" value={item.value} onChange={(e) => item.setValue(e.target.value)} options={item.options} required={item.required} label="none"/>
            }
            { (item.type === "select-ext") && 
                <DropDownExtended ref={ref} item={item} label="none"/>
            }
            { (item.type === "checkbox") &&
            <CheckBox ref={ref} title={item.title} id={item.id} checked={item.value} onChange={() => item.setValue(!item.value)} nolabel={true}/>
            }
        </form>
        :
        <InvisArticle extraClass={moreClass}>
            {children}
        </InvisArticle>
        }
        </>
    )

});

export const ValueDisplay = ({ item }) => {

    if (item.type === "select") {
        return (
            <span className="whitespace-nowrap">{item.options.find(option => option.value === item.value)?.label}</span>
        )
    } else if (item.type === "checkbox") {
        return (
            <span className="whitespace-nowrap">{item.value ? "Yes" : "No"}</span>
        )
    }
    return (
            <span className="whitespace-nowrap">{item.value}</span>
    )
}