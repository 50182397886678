import { createContext, useState } from 'react';
import standardBg from '../assets/img/newBG.webp';

export const NavContext = createContext();

export const NavProvider = ({children}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupTitle, setPopupTitle] = useState(null);
    const [popupContent, setPopupContent] = useState(null);
    const [popupType, setPopupType] = useState(null);
    const [popupFunc, setPopupFunc] = useState(null);
    const [popupCloseable, setPopupCloseable] = useState(true);
    const [popupMenu, setPopupMenu] = useState(null);
    const [menu, setMenu] = useState([]);
    const [bg, setBg] = useState(standardBg);
    const [pageTitle, setPageTitle] = useState("Documents And Dragons");
    const [tabs, setTabs] = useState([]);
    const [leftSideContent, setLeftSidebar] = useState(null);
    const [rightSideContent, setRightSidebar] = useState(null);
    const [closeFunc, setCloseFunc] = useState(null);
    const [form, setForm] = useState(false);
    const [formSubmitFunc, setFormSubmitFunc] = useState(null);

    return (
        <NavContext.Provider value={{ pageTitle, setPageTitle, bg, setBg, setShowPopup, setPopupFunc, setPopupTitle, setPopupContent, setPopupType, showPopup,  menu, setMenu, tabs, setTabs, leftSideContent, rightSideContent,
        popupTitle, popupContent, popupType, popupFunc, popupCloseable, setPopupCloseable, popupMenu, setPopupMenu,
        setLeftSidebar, setRightSidebar,
        closeFunc, setCloseFunc,
        form, setForm,
        formSubmitFunc, setFormSubmitFunc
         }}>
            {children}
        </NavContext.Provider>
    );
};
