import { InputBtnStd } from "./buttons";

export const NewPopupSection = ({ children }) => {
  return (
    <>
      <section className="relative flex flex-col bg-primary text-light border-stone w-1/3 md:max-w-[500px] max-w-[300px] min-w-[300px] bg-opacity-95">
        {children}
      </section>
    </>
  )
};

export const SideSection = ({ children }) => {
  
  return (
    <>
      <section
        className='relative top-shadow chain-in top-[10svh] pt-2 flex-col md:flex hidden items-center justify-start h-[90svh] text-light w-full min-h-[1px] bg-opacity-95 overflow-x-hidden overflow-y-auto'
      >
        {children}
      </section>
    </>
  );
};

export const InnerSection = ({ children, extraClass }) => {
  return (
    <>
      <section className={`${extraClass} relative fade-in overflow-x-hidden overflow-y-auto flex-col flex items-center text-center w-full h-full p-2`}>
        {children}
      </section>
    </>
  );
};

export const InnerFormSection = ({ children, onSubmit, formErrors, inputBtnText }) => {
      return (
      <>
        <form
          className="relative pt-1 overflow-x-hidden overflow-y-auto flex-col flex items-center w-full h-full justify-between"
          onSubmit = {onSubmit}
        >
          <section className="w-full items-center flex flex-col">
          {children}
          {formErrors && <ul className="text-red-500 text-shadow-red">
              {Object.keys(formErrors).map((key) => (
                <li key={key}>{formErrors[key]}</li>
              ))}
          </ul>}
          </section>
          {inputBtnText && 
          <BottomSection extraClass="justify-center">
            <InputBtnStd type="submit" text={inputBtnText}/>
          </BottomSection>}
        </form>
      </>
    );
  
};

export const ContentSection = ({ children }) => {
  return (
    <>
      <section className="grid md:grid-cols-3 gap-2 w-[95%]">
        {children}
      </section>
    </>
  );
};

export const BottomSection = ({ children, extraClass }) => {
  return (
    <>
      <section className="w-full">
      <hr className="border-future w-[90%] mx-auto"/>
      <section className={`${extraClass} relative md:flex-row flex-col flex w-full rounded-xl py-1 px-4`}>
        {children}
      </section>
      </section>
    </>
  );
};