import React from 'react';
import { useNavigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.navigate = this.props.navigate;
  }

  static getDerivedStateFromError(error) {
    // Set hasError to true when an error is caught
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error for debugging
    console.error(error, errorInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    // Only navigate to the error route when the error state changes
    if (this.state.hasError && !prevState.hasError) {
      this.navigate('/error');
    }
  }

  render() {
    // No need to render anything if we navigate away on error
    return this.props.children;
  }
}

export default function ErrorBoundaryWithNavigate(props) {
  const navigate = useNavigate();
  return <ErrorBoundary navigate={navigate} {...props} />;
}