import { HugeMedievalBtnStd, HrBtnStd } from '../components/buttons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { NavContext } from '../context';
import bg from '../../assets/img/newBG.webp';

export default function MainMain() {

    const { 
      setMenu,
      setBg
     } = useContext(NavContext);

    const navigate = useNavigate();
    useEffect(() => {
          setMenu([]);
          setBg(bg);
      }, [
        setMenu,
        setBg
      ]);    

    const animations = [
        { text: "Welcome to the ultimate worldbuilding platform", delay: 1500 },
        { text: "Create realms without bounds", delay: 3000 },
        { text: "Chronicle your character's exploits", delay: 4500 },
        { text: "Bring your stories to life with or without AI", delay: 6000 }
    ];

    return (
        <section className="relative flex-col col-span-6 flex items-center text-2xl font-bold text-shadow-big my-auto text-light w-full">
  
          <h1
            className="chain-in font-superserif text-light text-center font-bold p-4 text-5xl md:text-7xl"
          >
            Documents and Dragons
          </h1>
          {animations.map((item, index) => (
              <p
                  key={index}
                  className="fade-in-slow p-2 text-center text-base md:text-xl"
                  style={{ animationDelay: `${item.delay}ms` }}
              >
                  {item.text}
              </p>
          ))}
          <span className="fade-in-superslow p-2 text-center text-base md:text-xl" style={{'animationDelay': '6500ms'}}><HugeMedievalBtnStd text="Start building" func={() => navigate("/getstarted")}/></span>
          <span className="font-superserif text-sm mb-8 "><HrBtnStd text="Skip >" func={() => navigate("/getstarted")}/></span>
        </section>
    );
}